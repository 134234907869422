import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Layout, Link, Image } from 'components';
import * as styles from './404.module.scss';

const ThankYou = ({ location }) => {
  const { thankYouPageSettings } = useStaticQuery(thankYouPageSettingsQuery);
  const { data } = thankYouPageSettings;
  const {
    thank_you_title: title,
    thank_you_subtitle: subtitle,
    thank_you_cta_link_text: ctaText,
    thank_you_cta_link_slug: ctaSlug,
    thank_you_image: thankYouImage,
  } = data;

  return (
    <Layout location={location}>
      <div className={`container ${styles.container}`}>
        <Image className={styles.image} image={thankYouImage} alt="Opps this page has gone missing" />
        <div className={styles.content}>
          {title && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.title} />}
          {subtitle && <div dangerouslySetInnerHTML={{ __html: subtitle?.html }} className={styles.subtitle} />}
          {ctaSlug?.url && (
            <Link className="button secondary" to="/">
              {ctaText?.text}
            </Link>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ThankYou;

export const thankYouPageSettingsQuery = graphql`
  query thankYouPageSettings {
    thankYouPageSettings: prismicSettings {
      data {
        thank_you_title {
          html
          text
        }
        thank_you_subtitle {
          html
          text
        }
        thank_you_cta_link_text {
          html
          text
        }
        thank_you_cta_link_slug {
          url
        }
        thank_you_image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
